<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const { durationLeftQuestDay, prizePool, questData } = useFreeSpinQuest();

const emit = defineEmits<{ (event: "playQuest"): void }>();

const btnText = computed(() => {
	if (questData.value?.data?.dayInfo?.isCompleted) {
		return t("freeSpin.promo.btn.name3");
	}
	return questData.value?.data?.isSubscribed ? t("freeSpin.promo.btn.name2") : t("freeSpin.promo.btn.name");
});

const handleClick = () => {
	loginGuard({
		success: () => {
			if (questData.value?.data?.dayInfo?.isCompleted) {
				return;
			}
			open("LazyOModalFreeSpin");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
	emit("playQuest");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 270,
			height: 492,
			src: '/nuxt-img/free-spin/promo/bg-img.webp',
			loading: 'lazy',
			alt: 'free-spin-promotion-img'
		}"
		width="328px"
		height="246px"
		class="card-free-spin"
		@click="handleClick"
	>
		<div class="free">
			<ASvg name="48/fs-free" />
			<AText
				:size="12"
				class="text-tlalnepantla free-text text-carabanchel"
				:modifiers="['semibold', 'uppercase', 'center', 'condensed']"
			>
				{{ t("freeSpin.promo.label.name") }}
			</AText>
		</div>
		<AText :size="12" :modifiers="['bold', 'uppercase']">
			{{ t("freeSpin.promo.title") }}
		</AText>
		<MFreeSpinTimer :date="durationLeftQuestDay" :sizeDate="12" hideIcon />
		<AText :modifiers="['semibold']">
			{{ t("freeSpin.promo.text") }}
		</AText>
		<MPrizeFund variant="free-spin" class="fs-prize" img="/nuxt-img/prizes/freespins.png" :icon-size="24">
			<AText :size="32" class="text-tlalnepantla text-shadow" :modifiers="['semibold', 'condensed']">
				{{ numberFormat(prizePool ?? 0) }}
			</AText>
			<template #after>
				<AText :size="10" :modifiers="['bold', 'uppercase', 'condensed']" class="text-shadow">
					<i18n-t keypath="freeSpin.promo.text3">
						<template #br>
							<br />
						</template>
					</i18n-t>
				</AText>
			</template>
		</MPrizeFund>
		<AButton
			variant="primary"
			class="button"
			size="s"
			:modifiers="[questData?.data?.dayInfo?.isCompleted && 'disabled']"
		>
			<AText :modifiers="['semibold']" class="text-tlalnepantla">
				{{ btnText }}
			</AText>
		</AButton>
	</ACard>
</template>

<style lang="scss" scoped>
.card-free-spin {
	padding: 16px;
	justify-content: center;
	gap: 8px;
	background: linear-gradient(119deg, #5200ff 23.5%, #ff2c6b 84%, #ff9e2c 100%);

	& > *:not(img) {
		@include media-breakpoint-down(sm) {
			position: relative;
			z-index: 2;
		}
	}

	.button {
		min-width: 140px;
		width: max-content;
	}

	.fs-prize {
		gap: 4px;
	}

	.free {
		position: absolute;
		right: 12px;
		top: 0;
		width: 60px;
		height: 49px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 4px 16px 0;

		&:deep(svg) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&-text {
			position: relative;
			z-index: 2;
		}
	}
}
</style>
